import Vue from "vue";
import { io } from "socket.io-client";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { CHAT_GETTERS } from "@/views/apps/chat/constants/chat-store-constants";

let connectedToPush = false;

export async function startNotifications({ memberKey, communitySlug }) {
  // Inicializo el socket y lo almaceno en Vue
  console.log("init socket");
  Vue.prototype.$notifications = io(process.env.VUE_APP_NOTIFICATIONS, {
    transports: ["websocket", "polling"],
  });

  // Hacemos join al servicio
  console.log("join service");
  Vue.prototype.$notifications.emit("join", memberKey);

  // Pedimos las notificaciones
  console.log("get notifications");
  Vue.prototype.$notifications.emit("getNotifications", {
    memberKey,
    authorization: Vue.$cookies.get("accessToken"),
    communitySlug,
  });

  // En caso de recibir, las metemos en el store
  Vue.prototype.$notifications.on("notifications", (data) => {
    const isModalOpen = store.getters[CHAT_GETTERS.isIndividualChatVisible];
    if(!isModalOpen){
      if (data.type && data.type === "chat") {
        store.dispatch("notifications/setNotifications", [data]);
      }
    }
  });
  // this.$store.dispatch('notifications/setNotifications', {
  //   memberKey,
  //   authorization: Vue.$cookies.get("accessToken"),
  //   communitySlug
  // });

  // mostramos las notificaciones por pantalla
  console.log("show notification with push or toast");
  Vue.prototype.$notifications.on("newNotification", (data) => {
    console.log("New push notification!!!");

    if (!connectedToPush) {
      console.log("not connected to push");
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: data.title,
          text: data.description,
          icon: "InfoIcon",
        },
      });
    }
  });

  // guardamos las no leidas en el store
  console.log("saving unread in store");
  Vue.prototype.$notifications.on("notReadNotifications", async (data) => {
    await store.dispatch("notifications/unreadCount", data);
  });

  // En caso de que nos hayan mandado una notificación o hayamos enviado una, escucharemos este evento para volver a pedir
  console.log("refresh notifications");
  Vue.prototype.$notifications.on("refreshNotifications", (data) => {
    Vue.prototype.$notifications.emit("getNotifications", {
      memberKey,
      authorization: Vue.$cookies.get("accessToken"),
      communitySlug,
    });
  });
}

export async function readNotification(notificationID) {
  Vue.prototype.$notifications.emit("readNotification", {
    notificationID,
    authorization: Vue.$cookies.get("accessToken"),
    communitySlug: store.getters.currentCollective.slug,
  });

  Vue.prototype.$notifications.emit("getNotifications", {
    memberKey: store.getters.loggedMember.key,
    authorization: Vue.$cookies.get("accessToken"),
    communitySlug: store.getters.currentCollective.slug,
  });
}

export async function sendMixpanelNotification(title) {
  const mainSlug = store.getters.mainCollective?.slug;

  Vue.prototype.$notifications.emit("sendNotification", {
    type: ["mixpanel"],
    communitySlug: store.getters.currentCollective?.slug || "nectios",
    mixpanel: {
      title,
      properties: {
        communitySlug: store.getters.currentCollective?.slug || "nectios",
        date: new Date(),
        userKey: store.getters.loggedUser?.key,
        userFullName:
          store.getters.loggedUser?.name +
          " " +
          store.getters.loggedUser?.surname,
        userEmail: store.getters.loggedUser?.email,
        isMember: store.getters.loggedMember
          ? store.getters.loggedMember.isMember
          : null,
        isStaff: store.getters.loggedMember
          ? store.getters.loggedMember.isStaff
          : null,
        mainCommunity:
          !!store.getters.currentCollective && !!mainSlug ? mainSlug : null,
        spaceType: store.getters.currentCollective
          ? store.getters.currentCollective.mainType
          : null,
      },
      memberKey:
        store.getters.loggedMember?.key ||
        store.getters.loggedUser?.key ||
        null,
    },
  });
}
