<template>
  <div class="d-flex flex-column flex-lg-row justify-content-between w-100 align-items-center">
    <div class="d-flex w-100 justify-content-between align-items-center">
      <h5 class="title">
        {{ title }}
      </h5>
      <div class="d-flex align-items-center">
        <!-- Selector Language -->
        <b-form-radio-group
          v-if="showLanguageSelector"
          v-model="selectedLanguage"
          :options="locales"
          :placeholder="$t('form.type-placeholder')"
          label-type="text"
          class="mr-1 order-lg-0 order-1 d-none d-lg-flex"
          @input="changeLanguage"
        />
        <button
          type="button"
          aria-label="Close"
          class="close order-0 order-lg-1"
          @click="closeModal()"
        >
          ×
        </button>
      </div>
    </div>
    <!-- Selector Mobile -->
    <b-form-radio-group
      v-model="selectedLanguage"
      :options="locales"
      :placeholder="$t('form.type-placeholder')"
      label-type="text"
      class="order-lg-0 order-1 d-flex d-lg-none mt-1"
      @input="changeLanguage"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: { Type: String, default: false },
  },
  data() {
    return {
      selectedLanguage: this.currentLocale,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.slug ? this.mainCollective : this.currentCollective;
    },
    locales() {
      const { locales } = this.$store.getters;
      const communityLanguages = locales.filter((language) => this.availableLanguages.includes(language.value));
      return communityLanguages;
    },
    localesLength() {
      return this.locales ? this.locales.length : 1;
    },
    showLanguageSelector() {
      return this.localesLength > 1;
    },
    availableLanguages() {
      return this.mainSpace?.availableLanguages;
    },
    currentLocale() {
      const { currentLocale } = this.$store.state.locale;
      return this.availableLanguages?.find((item) => item === currentLocale) ? currentLocale : this.mainSpace.languageCode;
    },
  },
  created() {
    this.selectedLanguage = this.currentLocale;
    this.changeLanguage();
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    changeLanguage() {
      this.$emit('selectLanguage', this.selectedLanguage);
    },
  },
};
</script>

<style>

</style>
